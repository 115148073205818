













































































































































.scopeBlock {
    background-color: #dddddd;
    border: 1px solid #526f7a;
}
